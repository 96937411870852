@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    box-sizing: border-box;
}
.firebase-emulator-warning {
    display: none;
}
@import 'react-alice-carousel/lib/scss/alice-carousel.scss';

html,
body,
#root {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    font-family: 'Source Sans Pro', sans-serif;
}

img {
    max-width: 100%;
}

a {
    text-decoration: none;
}
h1,
h2,
h3 {
    font-weight: bold;
}
p,
h4,
h5,
h6,
input,
button,
ul li,
div {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: lighter;
}
p {
    font-size: 1.1rem;
    font-weight: lighter;
    margin: 0 0 10px 0;
}
small {
    font-size: 0.85rem;
    font-weight: lighter;
}
h2 {
    font-size: 2rem;
    margin: 1rem 0;
}
ul,
ol {
    margin: 0;
    padding: 0 0 0 1rem;
    li {
        list-style: none;
    }
}
@media (max-width: 450px) {
    p {
        font-size: 1rem;
    }
    h2 {
        font-size: 1.5rem;
    }
}
form {
    width: 100%;
}
label {
    display: flex;
    flex-direction: column;
    margin: 1rem 0 0.5rem;
    p {
        margin: 0 0 0.5rem;
        font-weight: normal;
    }
}
input,
textarea {
    padding: 1rem;
    border: 2px solid #c4c4c4;
    border-radius: 100px;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1rem;
    font-weight: bold;
}
.checkbox {
    display: block;
    position: relative;
    padding-left: 60px;
    margin-bottom: 20px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked ~ .checkmark:after {
            display: block;
        }
    }
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 40px;
        width: 40px;
        border: 2px solid #c4c4c4;
        border-radius: 5px;
        &:after {
            content: '';
            position: absolute;
            display: none;
        }
        &:after {
            left: 12px;
            top: 3px;
            width: 10px;
            height: 20px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
    p {
        padding-top: 10px;
        margin: 0;
    }
    &:hover input ~ .checkmark {
        background-color: #eee;
    }

    /* When the checkbox is checked, add a blue background */
    & input:checked ~ .checkmark {
        background-color: #5fab30;
    }
}

::placeholder {
    font-family: 'Source Sans Pro', sans-serif;
    color: #c4c4c4;
    font-size: 1rem;
    font-weight: bold;
}
button {
    border: 0;
    background: #c63f3c;
    border-radius: 4rem;
    padding: 1rem 2rem;
    font-size: 1.3rem;
    font-weight: bold;
    color: white;
    margin-top: 1rem;
    margin-bottom: 1rem;
    gap: 1rem;
    width: 100%;

    &:disabled {
        background-color: rgba(234, 0, 41, 0.5);
    }
}

.wrapper {
    @apply max-w-lg mx-auto;

    @media (max-width: 768px) {
        @apply px-8;
    }
}

.background {
    background: black;
}

.video {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.video iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.alice-carousel__dots {
    margin: 2rem 0 0 0;
    li {
        background: #ecf1f7;
        margin-right: 1rem !important;
        &.__active {
            background: #c7c7c7;
        }
    }
}

.legal {
    @apply max-w-lg mx-auto pb-8 px-4 text-balance;
    color: white;

    h1 {
        @apply text-3xl mb-4;
    }
    h2 {
        @apply text-xl;
    }
    p,
    li {
        @apply text-base mb-4 text-pretty;
    }
}

table {
    border: 1px solid #ccc;
    width: 100%;
    border-spacing: 0;
    tr {
        border-color: #ccc;
        td,
        th {
            border-bottom: 1px solid #ccc;
            border-left: 1px solid #ccc;
            padding: 3px 10px;
            &:first-child {
                border-left: 0;
            }
        }
        &:last-child {
            td,
            th {
                border-bottom: 0;
            }
        }
    }
    thead {
        tr {
            td,
            th {
                border-bottom: 1px solid #ccc !important;
            }
        }
    }
}

@keyframes btn-participa {
    0% {
        transform: scale(1, 1);
    }
    5% {
        transform: scale(1.1, 0.9);
    }
    20% {
        transform: scale(0.9, 1.1);
    }
    30% {
        transform: scale(1.05, 0.95);
    }
    35% {
        transform: scale(1, 1);
    }
    42% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(1, 1);
    }
}
